.container {
  margin: 100px;
  flex-shrink: 0;
  width: 100vw;
  @media (max-width: 768px) {
    margin: 20px;
  }
}

.containerMob {
  flex-shrink: 0;
  width: 100vw;
  margin: 20px;
}

.header {
  display: inline-grid;
}

.title {
  margin-bottom: 0px;
  float: left;
  text-align: left;
  color: var(--system-100, #020512);
  font-size: 30px;
  font-family: 'Exo 2';
  font-weight: 600;
}

.subtitle {
  color: var(--system-100, #020512);
  float: left;
  font-size: 16px;
  font-family: 'Exo 2';
  font-weight: 500;
}

.lessonsBlock {
  margin-top: 20px;
  display: flex;
  flex-shrink: 0;
}


.lessonsBlockMob {
  margin-top: 20px;
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
}

.element {
  margin-right: 20px;
  display: flex;
  width: 20vw;
  height: 103px;
  justify-content: center;
  align-items: center;
  padding: 8px;
  flex-shrink: 0;
  border-radius: 10px;
  background: var(--primary-aqua, #B4D8E6);
}

.elementMob {
  margin-right: 10px;
  width: 10.5rem;
  padding: 3px;
  margin-bottom: 15px;
  display: flex;
  height: 103px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 10px;
  background: var(--primary-aqua, #B4D8E6);
}

.elementContent {
  display: flex;
  width: 190px;
  text-align: left;
  flex-shrink: 0;
  overflow: hidden;
  color: var(--system-100, #020512);
  text-overflow: ellipsis;
  font-size: 16px;
  font-family: 'Exo 2';
}

.elementContentMob {
  margin-right: 10px;
  width: 6.5rem;
  display: flex;
  text-align: left;
  flex-shrink: 0;
  overflow: hidden;
  color: var(--system-100, #020512);
  text-overflow: ellipsis;
  font-size: 12px;
  font-family: 'Exo 2';
}

.red {
  border-radius: 10px;
  background: var(--primary-pink, #F0BED7);
}

.white {
  border-radius: 10px;
  border: 2px solid var(--primary-blue, #0D83C5);
  background-color: #fff;
}

.purpleBorder {
  border-radius: 10px;
  border: 3px solid var(--primary-purple, #A798D4);
}

.purpleBackground {
  border-radius: 10px;
  background: var(--primary-purple, #A798D4);
}

.yellow {
  border-radius: 10px;
  background: var(--primary-yellow, #FEFDD2);
}

