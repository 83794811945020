.header {
  display: flex;
  align-items: center;
  gap: 8px;
  background: #FEFEFB;
  box-shadow: 0px 0px 30px rgba(34, 51, 115, 0.1);
  border-radius: 0px 0px 20px 20px;
  position: fixed;
  top: 0px;
  left: 0;
  z-index: 900;
  width: 100%;
  height: 85px;
}

.headerLogo {
  margin-left: 100px;
  width: 112px;
  height: 24px;

  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #0D83C5;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.headerLogoMob {
  margin-left: 40px;
  width: 112px;
  height: 24px;
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #0D83C5;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.headerNav {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0px;
  padding-right: 100px;
  gap: 20px;
  flex: none;
  order: 1;
  flex-grow: 1;
}

.headerNavMob {
  padding-right: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0px;
  gap: 20px;
  flex: none;
  order: 1;
  flex-grow: 1;
}

.headerList {
  display: flex;
  flex-wrap: wrap;
}

.headerItem {
  list-style-type: none;
  margin-right: 20px;
}

.registerCourceBtn {
  border: 1px #0D83C5 solid;
  border-radius: 50px;
  padding: 5px 20px 5px 20px;
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #020512;
  text-decoration: none;
}

.link {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #020512;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

.headerItem:last-child {
  margin-right: 0;
}
