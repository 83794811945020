.main {
  width: 100%;
}

.container {
  margin-top: 50px;
  margin-left: 100px;
}

.containerMob {
  margin-top: 50px;
  margin-left: 15px;
}

.title {
  margin-bottom: 20px;
  display: flex;
  width: 820px;
  flex-direction: column;
  text-align: left;
  flex-shrink: 0;
  color: var(--system-100, #020512);
  font-size: 50px;
  font-family: 'Exo 2';
  font-weight: 600;
  line-height: 58px;
}

.titleMob {
  font-size: 25px;
  width: 350px;
  line-height: 35px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  text-align: left;
  flex-shrink: 0;
  color: var(--system-100, #020512);
  font-family: 'Exo 2';
  font-weight: 600;
}

.subtitle {
  margin-bottom: 20px;
  display: block;
  text-align: left;
  width: 400px;
  flex-direction: column;
  flex-shrink: 0;
  color: #000;
  flex-shrink: 0;
  font-size: 1.25rem;
  font-family: Exo 2;
  font-weight: 500;
}

.subtitleMob {
  font-size: 1rem;
  font-family: Exo 2;
  font-weight: 500;
  width: 20rem;
  margin-bottom: 20px;
  display: block;
  text-align: left;
  flex-direction: column;
  flex-shrink: 0;
  color: #000;
  flex-shrink: 0;
}

.blue {
  color: #0D83C5;
}

.startCourseBlock {

  width: 330px;
  height: 44px;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  border-radius: 0px 20px 20px 0px;
  background: var(--primary-yellow, #FEFDD2);
}

.startCourseBlockMob {
  margin-top: 25px;
  margin-bottom: 25px;
  margin-left: 15px;
  width: 330px;
  height: 44px;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  border-radius: 0px 20px 20px 0px;
  background: var(--primary-yellow, #FEFDD2);
}

.startCourceText {
  display: flex;
  flex-direction: row;
  padding-left: 75px;
  color: var(--system-100, #020512);
  font-size: 18px;
  font-family: 'Exo 2';
  font-weight: 500;
}

.startCourceTextMob {
  padding-left: 15px;
  display: flex;
  flex-direction: row;
  color: var(--system-100, #020512);
  font-size: 18px;
  font-family: 'Exo 2';
  font-weight: 500;
}

.marginX {
  margin: 0px 20px
}

.smile {
  width: 30px;
  height: 30px;
}

.bottomContainer {
  margin-left: 100px;
  margin-top: 0px;
}

.bottomContainerMob {
  margin-left: 15px;
  margin-top: 0px;
}

.list {
  padding-left: 0px;
  margin-bottom: 30px;
}

.listMob {
  padding-left: 0px;
  margin-bottom: 300px;
}

.item {
  color: var(--system-100, #020512);
  list-style-type: none;
  font-size: 14px;
  font-family: 'Exo 2';
  font-weight: 500;
}

.redMarker::before {
  content: "•";
  color: #F0BED7;
  margin-right: 10px;
  font-size: 25px;
}

.blueMarker::before {
  content: "•";
  color: #B4D8E6;
  margin-right: 10px;
  font-size: 25px;
}

.greenMarker::before {
  content: "•";
  color: #A798D4;
  margin-right: 10px;
  font-size: 25px;
}

.yellowMarker::before {
  content: "•";
  color: #0D83C5;
  margin-right: 10px;
  font-size: 25px;
}

.connectButton {
  display: flex;
  width: 260px;
  height: 57px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 50px;
  gap: 8px;
  background: var(--primary-blue, #0D83C5);
  box-shadow: 0px 0px 30px 0px rgba(34, 51, 115, 0.10);
  color: var(--system-0, #FEFEFB);
  text-align: center;
  font-size: 20px;
  font-family: 'Exo 2';
  font-weight: 700;
  margin-right: 20px;
}

.connectButtonMob {
  width: 160px;
  height: 37px;
  padding: 4px;
  font-size: 16px;
  margin-right: 10px;
  gap: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 50px;
  background: var(--primary-blue, #0D83C5);
  box-shadow: 0px 0px 30px 0px rgba(34, 51, 115, 0.10);
  color: var(--system-0, #FEFEFB);
  text-align: center;
  font-family: 'Exo 2';
  font-weight: 700;
}

.checkFreeButton {
  display: flex;
  width: 260px;
  height: 57px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 50px;
  border: 1px solid var(--primary-blue, #0D83C5);
  background: var(--background-main, #FEFEFB);
  box-shadow: 0px 0px 30px 0px rgba(34, 51, 115, 0.06);
  color: var(--primary-blue, #0D83C5);
  text-align: center;
  font-size: 14px;
  font-family: 'Exo 2';
  font-weight: 500;
}


.checkFreeButtonMob {
  width: 160px;
  height: 37px;
  padding: 4px;
  font-size: 10px;
  gap: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 50px;
  border: 1px solid var(--primary-blue, #0D83C5);
  background: var(--background-main, #FEFEFB);
  box-shadow: 0px 0px 30px 0px rgba(34, 51, 115, 0.06);
  color: var(--primary-blue, #0D83C5);
  text-align: center;
  font-family: 'Exo 2';
  font-weight: 500;
}

.groupButtons {
  display: flex;
  flex-direction: row;
}

.imageSection {
  position: relative;
}

.mainImage {
  width: 750px;
  flex-shrink: 0;
  position: absolute;
  top: 40px;
  right: 0px;
  z-index: -1;
}

.mainImageMob {
  width: 100%;
  top: 450px;
  right: 0px;
  flex-shrink: 0;
  position: absolute;
  z-index: -1;
}