.priceSection {
  flex-direction: row;
  width: 100%;
  background: var(--background-main, #FEFEFB);
  display: flex;
}

.priceSectionMob {
  width: 100%;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  margin-bottom: 100px;
  background: var(--background-main, #FEFEFB);
  display: flex;
}

.itNavigator {
  float: left;
  width: 45%;
  height: 401px;
  border-radius: 20px;
  background: var(--primary-aqua, #B4D8E6);
  margin-left: 80px;
  margin-bottom: 130px;
}

.itNavigatorMob {
  width: 85%;
  height: auto;
  margin-left: 30px;
  margin-bottom: 30px;
  float: left;
  border-radius: 20px;
  background: var(--primary-aqua, #B4D8E6);
}

.blockHeader {
  padding-top: 10px;
  margin-bottom: 10px;
  color: var(--system-100, #020512);
  text-align: center;
  font-size: 30px;
  font-family: 'Exo 2';
  font-weight: 600;
}

.blockHr {
  margin-left: 0px;
  width: auto;
  border-color: #0D83C5;
}

.price1 {
  color: #0D83C5;
  text-align: center;
  font-size: 40px;
  font-family: 'Exo 2';
  font-weight: 600;
}

.price2 {
  color: #FEFEFB;
}

.blockList {
  margin-top: 15px;
  margin-bottom: 0px;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 13px;
  margin-left: 30px;
}

.listItem {
  @media (max-width: 768px) {
    font-size: 0.875rem;
  }
  font-size: 1rem;
  font-family: Exo 2;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  list-style-type: '+ ';
}

.buttonGroup {
  display: flex;
  justify-content: center;
}

.itNavigationButton {
  margin-top: 50px;
  width: 300px;
  height: 57px;
  padding: 8px;
  gap: 8px;
  border-radius: 50px;
  border: 1px solid #0D83C5;
  background-color: #0D83C5;
  color: white;
  color: var(--system-0, #FEFEFB);
  text-align: center;
  font-size: 20px;
  font-family: 'Exo 2';
  font-weight: 700;
}

.itNavigationButton:active {
  background-color: #0174b7;
}

.itNavigatorNote {
  color: var(--system-0, #FEFEFB);
  text-align: center;
  font-size: 12px;
  font-family: 'Exo 2';
  font-weight: 600;
  margin-top: 5px;
}

.askForm {
  width: 45vw;
  margin-left: auto;
  display: flex;
  flex-direction: column;
}

.askFormMob {
  width: 90%;
  margin-left: auto;
  display: flex;
  flex-direction: column;
}

.askTitle {
  margin-top: 80px;
  margin-left: auto;
  margin-right: 120px;
  margin-bottom: 40px;
  text-align: left;
  display: flex;
  width: 505px;
  flex-direction: column;
  flex-shrink: 0;
  color: var(--system-100, #020512);
  font-size: 18px;
  font-family: 'Exo 2';
  font-weight: 500;
}

.askTitleMobile {
  width: 90%;
  margin: 0px 15px 15px;
  margin-left: auto;
  text-align: left;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  color: var(--system-100, #020512);
  font-size: 18px;
  font-family: 'Exo 2';
  font-weight: 500;
}

.input {
  margin-top: 15px;
  margin-right: 30px;
  height: 50px;
  padding: 0px 20px;
  align-items: center;
  gap: 8px;
  border-radius: 20px;
  border: 1px solid #B4D8E6;
  background:  #FEFEFB;
  box-shadow: 0px 0px 30px 0px rgba(34, 51, 115, 0.04);
  flex-direction: column;
  color: #757677;
  font-size: 16px;
  font-family: 'Exo 2';
}

.inputMob {
  margin: 0px 15px 15px;
  height: 50px;
  padding: 0px 20px;
  align-items: center;
  gap: 8px;
  border-radius: 20px;
  border: 1px solid #B4D8E6;
  background:  #FEFEFB;
  box-shadow: 0px 0px 30px 0px rgba(34, 51, 115, 0.04);
  flex-direction: column;
  color: #757677;
  font-size: 16px;
  font-family: 'Exo 2';
}


.askButton {
  margin-top: 35px;
  margin-right: 120px;
  margin-left: auto;
  padding: 6px 36px;
  width: 250px;
  justify-content: center;
  border: 1px solid #B4D8E6;
  align-items: center;
  gap: 8px;
  border-radius: 30px;
  background: #B4D8E6;
}

.askButtonMob {
  width: 60%;
  margin-top: 15px;
  margin-left: 110px;
  margin-right: 120px;
  padding: 6px 36px;
  justify-content: center;
  border: 1px solid #B4D8E6;
  align-items: center;
  gap: 8px;
  border-radius: 30px;
  background: #B4D8E6;
}

.askButtonMob:hover {
  cursor: pointer;
}

.askButtonMob:active {
  background-color: #92cfe8;
}

.askButton:hover {
  cursor: pointer;
}

.askButton:active {
  background-color: #92cfe8;
}

.label {
  position: absolute;
  margin-left: -40px;
  margin-top: -30px;
  display: inline-flex;
  transform: rotate(-9.728deg);
  padding: 6px 22px;
  align-items: flex-start;
  gap: 8px;
  border-radius: 100px;
  background: var(--primary-pink, #F0BED7);
  box-shadow: 0px 0px 30px 0px rgba(34, 51, 115, 0.10);
}

.labelText {
  color: var(--system-100, #020512);
  text-align: center;
  font-size: 16px;
  font-family: 'Exo 2';
  font-weight: 500;
}

.checkBox {
  margin-right: 10px;
}

.labelCourse {
  margin-bottom: 10px;
}

.title {
  width: 100%;
  font-size: 24px;
  text-align: center;
}

.buyInput {
  @media (max-width: 768px) {
    width: 100%;
  }
  margin-top: 15px;
  width: 60%;
  padding: 0px 10px;
  border-radius: 20px;
  border: 1px solid #B4D8E6;
  background:  #FEFEFB;
  box-shadow: 0px 0px 30px 0px rgba(34, 51, 115, 0.04);
  color: #757677;
  font-size: 16px;
  font-family: 'Exo 2';
}

.hint {
  font-size: 12px;
  text-align: left;
  margin-bottom: 20px;
}

.buttonSection {
  justify-content: center;
  display: flex;
  width: 100%;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}