.teamSection {
  margin-left: 100px;
  margin-right: 100px;
}

.teamSectionMob {
  width: 90%;
  margin: 30px 15px 15px
}

.title {
  color: var(--system-100, #020512);
  text-align: center;
  font-size: 38px;
  font-family: 'Exo 2';
  font-weight: 600;
  margin-bottom: 0px;
}

.subtitle {
  color: var(--system-100, #020512);
  margin-bottom: 20px;
  text-align: center;
  font-size: 16px;
  font-family: 'Exo 2';
  font-weight: 500;
}


.teacherBlock {
  margin-right: 20px;
  width: 70%;
  height: auto;
  flex-shrink: 0;
  border-radius: 20px;
  background: #0D83C5;
}

.teacherBlockMob {
  width: 90%;
  flex-direction: column;
  margin-bottom: 40px;
  margin-left: 20px;
  margin-right: 20px;
  height: auto;
  flex-shrink: 0;
  border-radius: 20px;
  background: #0D83C5;
}

.teacher {
  margin: 25px 45px 30px;
  color: #FEFEFB;
  text-align: left;
  font-size: 22px;
  font-family: 'Exo 2';
  font-weight: 600;
  margin-right: 0px;
  width: 300px;
  float: left;
}

.tips {
  display: flex;
  margin-left: 30px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 310px;
  margin-right: 0px;
  float: left;
}

.tipsMob {
  width: 90%;
  height: auto;
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  margin-right: 0px;
  float: left;
}

.tip {
  color: var(--system-0, #FEFEFB);
  font-size: 16px;
  font-family: 'Exo 2';
}

.tips li::before {
  content: '✓';
  font-weight: bold;
  margin-right: 10px;
}

.teacherImageFirst {
  @media (max-width: 768px) {
    width: 50%; 
  }
  margin-top: auto;
  margin-left: auto;
  width: 40%;
  height: auto;
  border-bottom-right-radius: 20px;
}

.teacherImageMob {
  width: 50%;
  height: auto;
  margin-top: auto;
  margin-left: auto;
  border-bottom-right-radius: 20px;
}

.teacherImageSecond {
  @media (max-width: 768px) {
    width: 50%; 
  }
  margin-top: auto;
  margin-left: auto;
  width: 40%;
  height: auto;
  border-bottom-right-radius: 20px;
}

.teamBlock {
  width: 70%;
  display: flex;
  flex-direction: row;
  margin-left: 20px;
}

.teamBlockMob {
  display: flex;
  margin-left: 20px;
  width: 90%;
  flex-direction: column;
}

.mb {
  margin-bottom: 60px;
}