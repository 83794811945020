.App {
  margin-top: 100px;
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: hidden;
}

.backdropBlur {
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0.48;
  top: 0;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 900;
  position: fixed;
}

.modal {
  @media (max-width: 768px) {
    width: 100%;
    height: 600px;
  }
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 450px;
  overflow-y: auto;
  padding: 30px;
  background-color: #FFFFFF;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  border-radius: 10px;
  border: 1px solid  #E6E6E6;
  box-shadow: 0 7px 8px -4px rgb(0 0 0 / 20%), 0 13px 19px 2px rgb(0 0 0 / 14%),
    0 5px 24px 4px rgb(0 0 0 / 12%);
}

.paragraph {
  margin-bottom: 15px;
  text-align: left;
  color: black;
}
.link {
  color: rgb(59, 87, 225)
}