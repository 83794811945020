.container {
  margin: 100px 205px;
}

.containerMob {
  width: 90%;
  margin: 30px 15px 150px
}

.rollover-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.rollover-list li {
  background-color: #fff;
  padding: 10px;
  transition: background-color 0.3s;
}

.rollover-list li:hover {
  background-color: #f2f2f2;
  cursor: pointer;
}

.title {
  color: var(--system-100, #020512);
  text-align: center;
  font-size: 38px;
  font-family: 'Exo 2';
  font-weight: 600;
}

.accordion {
  cursor: pointer;
  padding: 18px;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
}

/* Style the accordion panel. Note: hidden by default */
.panel {
  padding: 0 18px;
  background-color: white;
  display: none;
  overflow: hidden;
}

.hr {
  border:1px solid #0D83C5;
  width: 100%;
  margin-bottom: 20px;
  margin-left: 0px;
}

.accordion {
  position: relative;
}

[id*="openAccordion"], [id*="closeAccordion"] {
  height: 50px;
  display: block;
  position: relative;
}

[id*="closeAccordion"] {
  display: none;
}

.accordeons:hover .open {
  cursor: pointer;
}

.accordion a {
  color: #000000;
  font-size: 1.25em;
  font-weight: 600;
  text-decoration: none;
}

[id*="openAccordion"]:after, [id*="closeAccordion"]:after {
  content: "+";
  font-size: x-large;
  color: #0D83C5;
  float: right;
  font-weight: 400;
  top: 15px;
  z-index: 999;
}

.answer {
  color: var(--system-100, #020512);
  font-size: 16px;
  font-family: 'Exo 2';
  text-align: left;
}

.lAnswer {
  color: var(--system-100, #020512);
  font-size: 16px;
  font-family: 'Exo 2';
  text-align: left;
  margin-top: 40px;
}
.targetFix {
  display: block;
  top: 0;
  left: 0;
  position: fixed;
}

.accordionContent {
  height: 0;
  /* padding: 0 2.5%; */
  position: relative;
  overflow: hidden;
  width: 90%;
  transition: all 0.1s ease;
  -webkit-transition: all 0.1s ease;
  -moz-transition: all 0.1s ease;
}

.accordion span:target ~ .accordionContent {
  display: block;
  height: auto;
  padding-bottom: 20px;
  /* padding-top: 10px; */
}

.accordion span:target ~ [id*="closeAccordion"] {
  display: block;
}

.accordion span:target ~ [id*="openAccordion"] {
  display: none;
}

.accordion span:target ~ [id*="closeAccordion"]:after {
  content: "-";
}
