.actualitySection {
   margin: 150px 100px;
}

.actualitySectionMob {
  margin: 40px 10px;
}

.rightText {
  display: flex;
  flex-direction: column;
  float: right;
}

.title {
  color: #020512;
  text-align: center;
  font-size: 30px;
  font-family: 'Exo 2';
  font-weight: 600;
  float: right;
  text-align: right;
  margin: 0px;
}

.titleMob {
  font-size: 25px;
  color: #020512;
  text-align: center;
  font-family: 'Exo 2';
  font-weight: 600;
  float: right;
  text-align: right;
  margin: 0px;
}

.blue {
  color: #0D83C5;
}

.subtitle {
   width: 578px;
  flex-direction: column;
  flex-shrink: 0;
  color: var(--system-100, #020512);
  text-align: right;
  font-size: 16px;
  font-family: 'Exo 2';
  float: right;
  margin: 0px;
}

.subtitleMob {
  width: 90%;
  flex-direction: column;
  flex-shrink: 0;
  color: var(--system-100, #020512);
  text-align: right;
  font-size: 16px;
  font-family: 'Exo 2';
  float: right;
  margin: 0px;
}

.zero {
  display: flex;
}

.zeroMob {
  flex-direction: column;
  height: auto;
  display: flex;
}

.mobile {
  @media (max-width: 768px) {
    height:auto;
  }
  height:auto;
  display: flex;
  margin-top: 20px;
  flex-direction: row;
}

.image1 {
  margin: 5px;
  border-radius: 10px;
  width: 100%;
  height: auto;
  flex-shrink: 0;
}

.border1 {
  width: 250px;
  height: fit-content;
  padding: 10px 18px 10px 18px;
  justify-content: center;
  margin-right: 20px;
  align-items: center;
  border-radius: 20px;
  border: 2px solid var(--primary-pink, #F0BED7);
  background: var(--background-main, #FEFEFB);
}

.border1Mob {
  width: 48%;
  padding: 2px 4px;
  margin-right: 4%;
  height: fit-content;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  border: 2px solid var(--primary-pink, #F0BED7);
  background: var(--background-main, #FEFEFB);
}

.border2 {
  width: 250px;
  height: fit-content;
  padding: 10px 18px 10px 18px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  border: 2px solid var(--primary-pink, #0D83C5);
  background: var(--background-main, #FEFEFB);
}

.border2Mob {
  width: 48%;
  padding: 2px 6px;
  height: fit-content;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  border: 2px solid var(--primary-pink, #0D83C5);
  background: var(--background-main, #FEFEFB);
}

.firstGroup {
  display: flex;
}

.firstGroupMob {
  flex-direction: column;
  display: flex;
}

.mobileDirect {
  display: flex;
  flex-direction: row;
}

.mobileDirectMob {
  justify-content: space-between;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
}

.image3 {
  width: 100%;
  height: 95px;
}

.border3 {
  margin-top: 30px;
  margin-right: 20px;
  display: inline-flex;
  padding: 5px 5px 15px 14px;
  align-items: center;
  border-radius: 20px;
  border: 2px solid var(--primary-purple, #A798D4);
  background: var(--background-main, #FEFEFB);
}

.secondGroup {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
