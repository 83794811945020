@import url('https://fonts.googleapis.com/css2?family=Exo+2:wght@500;600&family=Pangolin&display=swap');

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

:root {
  --font-primary: 'Exo 2', sans-serif;
  --color-primary: #020512;
  --secondary-color: #FEFEFB;
}

body {
  background-color: white;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Exo 2';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h2 {
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 600;
  font-size: 38px;
  line-height: 46px;
}

h4 {
  font-family: var(--font-primary);
  color: var(--secondary-color);
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
}

.detailFont {
  font-family: var(--font-primary);
  color: var(--secondary-color);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
