.aboutCourse {
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  width: 100%;
  height: auto;
  top: 85px;
  align-items: center;
}

.course {
  margin-top: 20px;
  width: 100%;
  height: 100%;
  background-image: url('../../assets/BG IMG.jpg');
}

.courseElements {
  padding: 97px 10% 67px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: row;
}

.courseElementsMob {
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0px
}

.courseBlock {
  margin-right: 40px;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 295px;
  height: 201px;
  background: #0D83C5;
  box-shadow: 0px 0px 30px rgba(34, 51, 115, 0.1);
  border-radius: 20px;
}

.courseBlockMob {
  margin-bottom: 40px;
  margin-right: 0px;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 295px;
  height: 201px;
  background: #0D83C5;
  box-shadow: 0px 0px 30px rgba(34, 51, 115, 0.1);
  border-radius: 20px;
}


.titleCourse {
  margin-bottom: 0px;
  margin-top: 8px;
}

.doneLogo {
  margin-top: 11px;
  width: 40px;
  height: 40px;
}

.descSection {
  text-align: center;
  padding: 0px 43px;
}

.programBlock {
  margin-left: 100px;
  margin-right: 100px;
  margin-top: 45px
}

.programTitle {
  margin: 0;
}

.programSubTitle {
  margin-top: 5px;
}

.lessonsBlock {
  display: flex;
  flex-wrap: wrap;
}

.element {
  flex-basis: 25%;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 8px;
  position: absolute;
  width: 295px;
  height: 103px;
  background: #B4D8E6;
  border-radius: 10px;
}

.elementContent {
  margin: 32px 47px 32px 47px;
}

