.container {
  height: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: #0D83C5;
  border-radius: 20px 20px 0px 0px;
}

.footerButtons {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.list {
  margin: 0px;
  display: flex;
}

.listMob {
  flex-direction: column;
  text-align: center;
  margin: 0px;
  display: flex;
}

.socialList {
  margin: 0px;
  display: flex;
  flex-direction: row;
}

.item {
  margin-top: 0px;
  margin-bottom: 0px;
  list-style-type: none;
  margin-right: 40px;
}

.itemMob {
  margin-bottom: 10px;
  margin-top: 0px;
  list-style-type: none;
  margin-right: 40px;
}

.link {
  color: white;
  text-decoration: none;
}

.socialNetworks {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.network {
  list-style-type: none;
  margin-right: 20px;
}

hr {
  margin-left: 10%;
  margin-top: 15px;
  width: 80%;
  border: 1px solid #FEFEFB;
}

p {
  color: var(--system-20, #CBCCCD);
  margin-top: 5px;
  text-align: center;
}

.phone {
  color: white;
  display: flex;
  flex-direction: row;
}

.mr {
  margin-right: 4px;
}