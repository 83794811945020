.container {
  height: 322px;
  flex-shrink: 0;
  border-radius: 20px;
  background: var(--primary-blue, #0D83C5);
}

.containerMob {
  height: auto;
  width: 100vw;
  margin-top: 100px;
  flex-shrink: 0;
  border-radius: 20px;
  background: var(--primary-blue, #0D83C5);
}

.freeLabel {
  display: inline-flex;
  transform: rotate(-9.728deg);
  padding: 6px 22px;
  margin-top: 15px;
  margin-left: 200px;
  align-items: flex-start;
  gap: 8px;
  border-radius: 100px;
  background: var(--primary-pink, #F0BED7);
  box-shadow: 0px 0px 30px 0px rgba(34, 51, 115, 0.10);
  color: var(--system-100, #020512);
  text-align: center;
  font-size: 16px;
  font-family: 'Exo 2';
  font-weight: 500;
}

.titleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  width: 100vw;
  display: flex;
  width: 774px;
  text-align: center;
  margin: 0px;
  flex-shrink: 0;
  color: var(--background-main, #FEFEFB);
  font-size: 38px;
  font-family: 'Exo 2';
  font-weight: 600;
  margin-bottom: 10px;
}

.titleMob {
  width: 100% !important;
  display: flex;
  width: 774px;
  text-align: center;
  margin: 0px;
  flex-shrink: 0;
  color: var(--background-main, #FEFEFB);
  font-size: 24px;
  font-family: 'Exo 2';
  font-weight: 600;
  margin-bottom: 10px;
}

.timer {
  display: flex;
  width: auto;
  height: 26px;
  flex-direction: column;
  flex-shrink: 0;
  color: var(--background-main, #FEFEFB);
  text-align: center;
  font-size: 20px;
  font-family: 'Exo 2';
  font-weight: 500;
  margin-bottom: 40px;
}

.connectButtonSecond {
  margin-bottom: 40px;
  display: flex;
  width: 300px;
  height: 57px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 50px;
  background: var(--system-0, #FEFEFB);
  box-shadow: 0px 0px 30px 0px rgba(212, 218, 240, 0.39);
  color: var(--primary-blue, #0D83C5);
  text-align: center;
  font-size: 20px;
  font-family: Exo 2;
  text-decoration: none;
  font-weight: 700;
}

.clock {
  color: white;
  display: flex;
  flex-direction: row;
}